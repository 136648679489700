import { ZeroExtraPipe } from './zero-extra.pipe';
import { DataComHoraPipe } from './data-com-hora.pipe';
import { VirgulaPipeSemZeroPipe } from './virgula-pipe-sem-zero.pipe';
import { CommonModule } from '@angular/common';
import { VirgulaPipe } from './virgula.pipe';
import { NgModule } from "@angular/core";
import { DataPipe } from './data.pipe';
import { DataJavascriptPipe } from './data-javascript.pipe';
import { BoolPipe } from './bool.pipe';
import { SimNaoPipe } from './sim-nao.pipe'
import { CpfCnpjPipe } from './cpf-cnpj.pipe';

@NgModule({
    declarations: [
        VirgulaPipe,
        VirgulaPipeSemZeroPipe,
        DataPipe,
        DataComHoraPipe,
        DataJavascriptPipe,
        ZeroExtraPipe,
        BoolPipe,
        SimNaoPipe,
        CpfCnpjPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        VirgulaPipe,
        VirgulaPipeSemZeroPipe,
        DataPipe,
        DataComHoraPipe,
        DataJavascriptPipe,
        ZeroExtraPipe,
        BoolPipe,
        SimNaoPipe,
        CpfCnpjPipe,
    ],
})
export class PipesModule { }