export class PersonFisical {

    Id: number = 0
    ContractorId: number = 0
    Naturalness: string = ''
    Nationality: string = ''
    Race: string = ''
    BloodType: string = ''
    CnhNumber: string = ''
    CnhCategory: string = ''
    CnhMaturty: any = ''
    VoterTitle: string = ''
    VoterTitleZone: string = ''
    VoterTitleSection: string = ''
    ReservistNumber: string = ''
    ReservistCategory: string = ''
    MotherName: string = ''
    FatherName: string = ''
    CommercialReferency: string = ''
    IncomeMonthly: number = 0
    Profession: string = ''
    LocalJob: string = ''
    StateCivil: string = ''
    CnhOrganEmitter: string = ''
    CnhExpedition: any = ''
    ConjugateCompanion: string = ''
    LevelFormation: number = 0
    PisDateRegister: any = ''
    PisNumber: string = ''
    PisBank: string = ''
    PisAgency: string = ''
    PisAgencyDigit: string = ''
    CtpsNumber: string = ''
    CtpsSerie: string = ''
    CtpsDateExpedition: any = ''
    CtpsUf: string = ''
    GrauInstruction: string = ''
    NrRic: string = ''
    OrganEmitterRic: string = ''
    DataExpeditionRic: any = ''
    NrRne: string = ''
    OrganEmitterRne: string = ''
    DataExpeditionRne: any = ''
    CodCityBirth: number = 0
    UfBirth: string = ''
    CodPaisBirthRfb: number = 0
    CodPaisNacionalityRfb: number = 0
    NrInscOrgabClass: string = ''
    EmitterOrganClass: string = ''
    DateExpeditionOrgClass: any = ''
    DateValidOrgClass: any = ''
    ForeignDtArrival: any = ''
    ForeignDtNaturalization: any = ''
    ForeignMarriedBr: string = ''
    ForeignSonBr: string = ''
    DefFisical: boolean = false
    DefVisual: boolean = false
    DefAuditory: boolean = false
    DefMental: boolean = false
    DefIntelectual: boolean = false
    Rehabilitated: boolean = false
    DateBirth: any = ''
    Rg: string = ''
    OrganRg: string = ''
    DateEmitterRg: any = ''
    Sex: string = ''
    SocialName: string = ''
    PessoaId: string = ''

    static datas() {
        return ['CnhMaturty', 'CnhExpedition', 'PisDateRegister', 'CtpsDateExpedition', 'DataExpeditionRic',
        'DataExpeditionRne', 'DateExpeditionOrgClass', 'DateValidOrgClass', 'ForeignDtArrival', 'ForeignDtNaturalization', 'DateBirth', 'DateEmitterRg']
    }

    static checkbox() {
        return ['DefFisical','DefVisual','DefAuditory','DefMental','DefIntelectual','Rehabilitated']
    }

    static relacionamentos() {
        return ['PessoaId']
    }
    
    static referencias(){
        return [{chave: 'PessoaId', referencia: 'Pessoa'}]
    }
    
    static expanded(){
        return ['PessoaId']
    }

}