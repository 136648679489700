import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { getUrlFinanceiro, getUrlContabil, USUARIO_STORAGE_KEY,
    TOKEN_STORAGE_KEY,
  URL_BANCO_STORAGE_KEY,
  VERSAO_SISTEMA,
  URL_API_STORAGE_KEY,
  EMPRESA_STORAGE_KEY,
  TOKEN_TEMP_STORAGE_KEY,
  EMPRESA_COMPLETA_STORAGE_KEY,
  PERMISSOES,
  API_AUTH
} from '../../controller/staticValues';
import { AuthService } from '../../auth/service/auth.service';
import { DadosDefaultService } from '../../services/dados-default.service';
import { NetworkService } from '../../services/network.service';
import { Util } from '../../controller/Util';
import {Subscription} from "rxjs";
import {ConfirmationService, MessageService} from "primeng/api";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-carregar-dados-login',
  templateUrl: './carregar-dados-login.component.html',
  styleUrls: ['./carregar-dados-login.component.css']
})
export class CarregarDadosLoginComponent  implements OnInit {

    $listarEmpresaSubscribe: Subscription;
    $quartaEtapaSubscribe: Subscription;
    $buscarClienteSubscribe: Subscription;
    $buscarEmpresaSubscribe: Subscription;

    constructor(private authService: AuthService, public confirmationService: ConfirmationService, public networkService: NetworkService, public router: Router, public messageService: MessageService, private dadosDefault: DadosDefaultService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.params.subscribe(v => {
                let usuario = JSON.parse(v.USUARIO);
                let empresa = JSON.parse(v.EMPRESA);
                usuario.contractor_photo = String(usuario.contractor_photo.replace(/['+']/g, '/'))
                if (empresa.caminho_foto !== null) {
                    empresa.caminho_foto = String(empresa.caminho_foto.replace(/['+']/g, '/'))
                    empresa.doc_format = String(empresa.doc_format.replace(/['+']/g, '/'))
                }
                sessionStorage.setItem(USUARIO_STORAGE_KEY, JSON.stringify(usuario));
                sessionStorage.setItem(TOKEN_TEMP_STORAGE_KEY, usuario.token);
                sessionStorage.setItem(EMPRESA_STORAGE_KEY, JSON.stringify(empresa));
                this.router.navigate(['/home'], {replaceUrl: true})
        });
    }
}
