import { Validators } from "@angular/forms"

export class PessoaContractor {
Id: number = 0
InternalCode: string = ''
Nome: string = ''
CaminhoFoto: string = ''
Email: string = ''
Fone1: string = ''
Fone2: string = ''
Celular: string = ''
Contato: string = ''
InscricaoEstadual: string = ''
InscricaoMunicipal: string = ''
Logradouro: string = ''
Numero:	string = ''
Complemento: string = ''
Cep: string = ''
Bairro: string = ''
Ativo: boolean = false
DateCreation: any = ''
DateUpdate: any = ''
Fantasia: string = ''
Cidade:	string = ''
Uf: string = ''
CodigoIbge: number = 0
ContractorId: number = 0
PessoaId: string = ''
PersonFisicalId: string = ''

static datas(){
    return ['DateCreation', 'DateUpdate']
}

static checkbox(){
    return ['Ativo']
}

static validacoes(){
    return [
        {campo: 'Nome', validation: Validators.compose([Validators.required])},
    ]
}

static relacionamentos() {
    return ['PessoaId', 'PersonFisicalId']
}

static referencias(){
    return [{chave: 'PessoaId', referencia: 'Pessoa'}, {chave: 'PersonFisicalId', referencia: 'PersonFisical'}]
}

static expanded(){
    return ['PessoaId', 'PersonFisicalId']
}

}