<ng-template #conteudo>
    <app-page-divided [formGroup]="form" [padrao]="true">
        <div principal>
            <p-card [formGroup]="form">
                <div *ngIf="primeiraEtapa" class="p-grid p-fluid" formGroupName="PessoaForm">
                    <div class="p-md-10">
                            <div class="p-md-12">
                                <app-input-cpf-cnpj primeiro [label]="'Cpf / Cnpj'" [row]="true"
                                                    (onBlur)="buscaCnpj($event)" (onKeypress)="buscaCnpj($event)"></app-input-cpf-cnpj>
                            </div>
                    </div>
                    <div class="p-md-2">
                        <div class="p-md-12" style="margin-top: 25px;">
                            <button pButton label="Avançar" (click)="avancarOuVoltar(2)"></button>
                        </div>
                    </div>
                </div>
            </p-card>
        </div>
    </app-page-divided> 
</ng-template>