<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3" style="font-weight: bold; font-size: 16px;">CNPJ:
                    11.378.004/0001-24
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                    <app-input [label]="'Descrição do Cargo'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-left: 100px;">
                    <div>
                        <span>Ativo</span>
                    </div>
                    <div formGroupName="PessoaForm">
                        <p-toggleButton [style]="{'width':'100px'}" [onLabel]="onLabel" [offLabel]="offLabel"
                            formControlName="Ativo"></p-toggleButton>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Código CBO'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input-money [label]="'Piso Salarial'"></app-input-money>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Código'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-select [label]="'Contrato de Sindicato'"></app-select>
                </div>                
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 14px; margin-top: 10px;">Data Inclusão:
                    01/01/2021
                </div>
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 14px; margin-top: 10px;">Data Alteração:
                    10/09/2021
                </div>
                <div class="p-field p-col-12 p-md-2 p-offset-4">
                    <button pButton type="button" [label]="'Cancelar'" class="p-button-danger" (click)="cancelar()"></button>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <button pButton type="button" [label]="'Salvar'"></button>
                </div>
            </div>
        </p-card>        
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>