<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3" style="font-weight: bold; font-size: 16px;">CNPJ:
                    11.378.004/0001-24
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                    <app-input [label]="'Descrição da Rubrica'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 30px;">
                    <p-checkbox [label]="'Incide INSS'"></p-checkbox>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 30px;">
                    <p-checkbox [label]="'Incide FGTS'"></p-checkbox>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Código da Rubrica'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Identificador de Rubrica'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 30px;">
                    <p-checkbox [label]="'Incide IRRF'"></p-checkbox>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 30px;">
                    <p-checkbox [label]="'Incide Férias'"></p-checkbox>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                    <app-input-pesquisar-plano-conta [label]="'Plano de Conta'"></app-input-pesquisar-plano-conta>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 20px;">
                    <button pButton type="button" [label]="'Cancelar'" class="p-button-danger" (click)="cancelar()"></button>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 20px;">
                    <button pButton type="button" [label]="'Salvar'"></button>
                </div>
            </div>
            <app-painel title="Vigências da Rúbrica">
                <p-card>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <app-input [label]="'Inicio da Vigência'"></app-input>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <app-input [label]="'Fim da Vigência'"></app-input>
                        </div>
                        <div class="p-field p-col-12 p-md-6" style="margin-top: 16px;">
                            <div class="p-inputgroup">
                                <input #inputPesquisa appUppercaseNotForm pInputText
                                    placeholder="Código Natureza Rúbrica">
                                <button pButton type="button" icon="fa fa-search" class="ui-button-warn"></button>
                            </div>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Tipo de Rubrica'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Tipo de Incidencia INSS'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Tipo de Incidencia FGTS'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Tipo Incidencia IRRF'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Incidencia FGTS Processo'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Incidencia INSS Processo'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Folha Processo Judicial'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Status e-Social'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-2" style="margin-top: 20px;">
                            <button pButton type="button" [label]="'Alterar Vigência'"></button>
                        </div>
                        <div class="p-field p-col-12 p-md-2" style="margin-top: 20px;">
                            <button pButton type="button" [label]="'Cria Nova Vigência'"></button>
                        </div>
                    </div>
                </p-card>
            </app-painel>
        </p-card>
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>