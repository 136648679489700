<div class="topbar clearfix">
    <div class="topbar-left" routerLink="/home">
        <div class="logo"></div>
    </div>

    <div class="topbar-right">
        <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
            <i class="pi pi-angle-left"></i>
        </a>

        <a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)">
            <i class="pi pi-ellipsis-v"></i>
        </a>

        <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
            <i class="pi pi-bars"></i>
        </a>

        <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
            <li #profile class="profile-item" *ngIf="app.profileMode==='top'||app.isHorizontal()"
                [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">

                <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                    <img class="profile-image" src="assets/layout/images/avatar.png" />
                    <span class="topbar-item-name">Jane Williams</span>
                </a>

                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-user"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-lock"></i>
                            <span>Privacy</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-cog"></i>
                            <span>Settings</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
                <a href="#" (click)="app.onTopbarItemClick($event,settings)">
                    <i class="topbar-icon pi pi-cog"></i>
                    <span class="topbar-item-name">Settings</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-palette"></i>
                            <span>Change Theme</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-heart"></i>
                            <span>Favorites</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-lock"></i>
                            <span>Lock Screen</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-image"></i>
                            <span>Wallpaper</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li #messages [ngClass]="{'active-top-menu':app.activeTopbarItem === messages}">
                <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                    <i class="topbar-icon pi pi-envelope animated swing"></i>
                    <span class="topbar-badge animated rubberBand">5</span>
                    <span class="topbar-item-name">Messages</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar1.png" width="35"/>
                            <span>Give me a call</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar2.png" width="35"/>
                            <span>Sales reports attached</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar3.png" width="35"/>
                            <span>About your invoice</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar2.png" width="35"/>
                            <span>Meeting today at 10pm</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar4.png" width="35"/>
                            <span>Out of office</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li #notifications [ngClass]="{'active-top-menu':app.activeTopbarItem === notifications}">
                <a href="#" (click)="app.onTopbarItemClick($event,notifications)">
                    <i class="topbar-icon pi pi-bell"></i>
                    <span class="topbar-badge animated rubberBand">4</span>
                    <span class="topbar-item-name">Notifications</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-sliders-h"></i>
                            <span>Pending tasks</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-calendar"></i>
                            <span>Meeting today at 3pm</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-download"></i>
                            <span>Download documents</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="pi pi-ticket"></i>
                            <span>Book flight</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li style="margin-top: 0px;">
                <span (click)="abrirModalTrocarEmpresa()" class="link-pessoa" style="font-size: 13px;color: white;">Empresa:</span><br>
                <span (click)="abrirModalTrocarEmpresa()" class="link-pessoa" style="color: #dddddd;">{{empresa}}</span>
            </li>
            <!-- <li #search class="search-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === search}"
                (click)="app.onTopbarItemClick($event,search)">
                <span class="p-input-icon-left">
                    <i class="topbar-icon pi pi-search"></i>
                    <input type="text" pInputText placeholder="Search">
                </span>
            </li> -->
        </ul>
    </div>
</div>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #trocarempresa></button>
</div>

<app-modal [fundo]="true" *appModalOpenOnClick="[trocarempresa, '1Acd5WGj78']" [hash]="'1Acd5WGj78'">
    <app-modal-trocar-empresa #content (dadosSalvos)="null" [data]="null"></app-modal-trocar-empresa>
</app-modal>