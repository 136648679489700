<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                    <app-input-pesquisar-pessoa [label]="'Proprietário'"></app-input-pesquisar-pessoa>
                </div>
                <div class="p-field p-col-12 p-md-3" style="margin-top: 30px; margin-left: 25px;">
                    <p-checkbox [label]="'Obra Própria'" [binary]="true"></p-checkbox>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-select [label]="'Tipo de Inscrição'"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Inscrição'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 20px;">
                    <button pButton type="button" [label]="'Buscar Dados'"></button>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                    <app-input [label]="'Nome'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Inscrição Municipal'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <div>
                        <span>Ativo</span>
                    </div>
                    <div formGroupName="PessoaForm">
                        <p-toggleButton [style]="{'width':'100px'}" [onLabel]="onLabel" [offLabel]="offLabel"
                            formControlName="Ativo"></p-toggleButton>
                    </div>
                </div>                
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4" style="margin-left: 10px;">
                    <div>Cep</div>
                    <div style="display: flex;justify-content: flex-start;">
                        <div style="padding-right: 0px;padding-left: 0px;">
                            <p-inputMask id="cep" (onBlur)="verificaCepValido($event)" mask="99999-999">
                            </p-inputMask>
                        </div>
                        <button pButton type="button" icon="fa fa-search" (click)="modalCepVisible = true"></button>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6" style="margin-left: -10px;">
                    <app-input [label]="'Endereço'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Número'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Bairro'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Cidade'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'UF'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Código GPS'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-select [label]="'Tipo Empreitada'"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 20px;">
                    <button pButton type="button" [label]="'Cancelar'" class="p-button-danger" (click)="cancelar()"></button>
                </div>
            </div>

        </p-card>
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>