import { InputPesquisarPlanoContaComponent } from './../components/input-pesquisar-plano-conta/input-pesquisar-plano-conta.component';
import { ModalPesquisarPlanoContaComponent } from './../components/input-pesquisar-plano-conta/modal-pesquisar-plano-conta/modal-pesquisar-plano-conta.component';
import { InputPesquisarPessoaComponent } from './../components/input-pesquisar-pessoa/input-pesquisar-pessoa.component';
import { ModalPesquisarPessoaComponent } from './../components/input-pesquisar-pessoa/modal-pesquisar-pessoa/modal-pesquisar-pessoa.component';
import { ModalCadastrarPessoaComponent } from './modal-cadastro-pessoa/modal-cadastrar-pessoa.component';
import { LayoutsModule } from './../layout/layouts.module';
import { PesquisarCepComponent } from './pesquisar-cep/pesquisar-cep.component';
import { ModalTrocarEmpresaComponent } from './modal-trocar-empresa/modal-trocar-empresa.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DialogModule} from "primeng/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TableModule} from "primeng/table";
import {TemaModule} from "../tema.module";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {PipesModule} from '../pipes/pipes.module';
import {DiretivasModule} from '../diretivas/diretivas.module';
import {ComponentsModule} from '../components/components.module';
import {ModalSelecionarEmpresaEscritorioComponent} from '../modais/modal-selecionar-empresa-escritorio/modal-selecionar-empresa-escritorio.component';

@NgModule({
  declarations: [
      ModalSelecionarEmpresaEscritorioComponent,
      ModalTrocarEmpresaComponent,      
      PesquisarCepComponent,
      ModalCadastrarPessoaComponent,
      ModalPesquisarPessoaComponent,
      InputPesquisarPessoaComponent,
      ModalPesquisarPlanoContaComponent,
      InputPesquisarPlanoContaComponent,
      //ModalPagamentoViaContaCorrenteComponent,
  ],
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        TemaModule,
        ProgressSpinnerModule,
        PipesModule,
        DiretivasModule,
        ComponentsModule,
        LayoutsModule,
    ],
    exports: [
        ModalSelecionarEmpresaEscritorioComponent,
        ModalTrocarEmpresaComponent,        
        PesquisarCepComponent,
        ModalCadastrarPessoaComponent,
        ModalPesquisarPessoaComponent,
        InputPesquisarPessoaComponent,
        ModalPesquisarPessoaComponent,
        InputPesquisarPlanoContaComponent,
        //ModalPagamentoViaContaCorrenteComponent,
    ]
})
export class ModaisModule { }
