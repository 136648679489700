import { Validators } from "@angular/forms"

export class Pessoa {

Id: number = 0
InternalCode: string = ''
Nome: string = ''
CpfCnpj: string = ''
TipoInscricao: string = ''
Tipo: string = ''
CaminhoFoto: string = ''
ContribuinteIcms: string = ''
Email: string = ''
Crt: string = ''
Fone1: string = ''
Fone2: string = ''
Celular: string = ''
Contato: string = ''
InscricaoEstadual: string = ''
InscricaoMunicipal: string = ''
Logradouro: string = ''
Numero: string = ''
Complemento: string = ''
Cep: string = ''
Bairro: string = ''
Ativo: boolean = false
DateCreation: any = ''
DateUpdate: any = ''
Fantasia: string = ''
IdentidadeEstrangeira: string = ''
Cidade: string = ''
Uf: string = ''
CodigoIbge: number = 0
ContractorId: string = ''
CityId: string = ''

static datas(){
    return ['DateCreation', 'DateUpdate']
}

static checkbox(){
    return ['Ativo']
}

static validacoes(){
    return [
        {campo: 'Nome', validation: Validators.compose([Validators.required])},
    ]
}

static mascaras() {
    return ['CpfCnpj', 'Cep']
}

static relacionamentos() {
    return ['ContractorId', 'CityId']
}

static referencias(){
    return [{chave: 'ContractorId', referencia: 'Contractor'}, {chave: 'CityId', referencia: 'City'}]
}

static expanded(){
    return ['ContractorId', 'CityId']
}

}