import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-menu',
    // templateUrl: './app.menu.component.html'
    template: `<ul class="ultima-menu ultima-main-menu clearfix">
                    <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
              </ul>`
})
export class AppMenuComponent implements OnInit {

    model: any[];
     
    constructor(public app: AppMainComponent, private router: Router) {}

    ngOnInit() {
        this.model = [
            {
                label: 'Dados do Empregador', icon: 'fa fa-building',
                items: [
                    { label: 'Cadastros', icon: 'pi pi-cog', routerLink: ['empregador'] },
                    { label: 'Estabelecimentos', icon: 'pi pi-cog', },
                    // { label: 'Lotações', icon: 'pi pi-cog', },
                    // { label: 'Cargos e Funções', icon: 'pi pi-cog', },
                    // { label: 'Horários / Turnos', icon: 'pi pi-cog', },
                    // { label: 'Rubricas', icon: 'pi pi-cog',  },
                    // { label: 'Feriados', icon: 'pi pi-cog', },
                    // { label: 'Parametros Contabeis', icon: 'pi pi-cog', },
                ]
            },
            {
                label: 'Colaboradores', icon: 'fa fa-line-chart',
                items: [
                    { label: 'Funcionários', icon: 'pi pi-cog', routerLink: ['funcionario'] },
                    { label: 'Sócios', icon: 'pi pi-cog', routerLink: ['socios'] },
                    { label: 'Estagiários', icon: 'pi pi-cog', },
                    { label: 'Autonomos', icon: 'pi pi-cog', },
                    {
                        label: 'Importar Dados', icon: 'pi pi-cog',
                        items: [
                            { label: 'Arquivo RAIS', icon: 'pi pi-cog', },
                            { label: 'Planilha Excel', icon: 'pi pi-cog', },
                            { label: 'RE-Sefip', icon: 'pi pi-cog', },
                        ]
                    },
                ]
            },
            {
                label: 'Movimentos', icon: 'fa fa-bar-chart',
                items: [
                    { label: 'Lançamentos', icon: 'pi pi-bars', },
                    { label: 'Adiantamento Salarial', icon: 'pi pi-cog', },
                    { label: 'Faltas', icon: 'pi pi-cog', },
                    { label: 'Arquivo de Ponto Eletrônico', icon: 'pi pi-cog', },
                    { label: 'Alteração Salárial', icon: 'pi pi-cog', },
                    { label: 'Afastamentos', icon: 'pi pi-cog', },
                    { label: 'Retenções em NF', icon: 'pi pi-cog', },
                    { label: 'Produção Rural(Venda/Compra)', icon: 'pi pi-cog', }
                ]
            },
            {
                label: 'Benefícios', icon: 'fa fa-address-card',
                items: [
                    { label: 'Vale Transporte', icon: 'pi pi-cog', },
                    { label: 'Vale Alimentação', icon: 'pi pi-cog', },
                ]
            },
            {
                label: 'Processamento', icon: 'fa fa-building-o',
                items: [
                    { label: 'Folha Mensal', icon: 'pi pi-cog', },
                    { label: 'Adiantamentos', icon: 'pi pi-cog', },
                    { label: 'Férias', icon: 'pi pi-cog', },
                    { label: 'Décimo Terceiro', icon: 'pi pi-cog', },
                    { label: 'Rescisão', icon: 'pi pi-cog', },
                    { label: 'Transmissão Financeiro/Contábil', }
                ]
            },
            {
                label: 'Relatórios', icon: 'fa fa-building-o',
                items: [

                ]
            },
            {
                label: 'Arquivos Legais', icon: 'fa fa-area-chart',
                items: [
                    { label: 'SEFIP', icon: 'pi pi-cog', },
                    { label: 'GRRF', icon: 'pi pi-cog', },
                    { label: 'DIRF', icon: 'pi pi-cog', },
                    { label: 'CAGED', icon: 'pi pi-cog', },
                    { label: 'Seguro-Desemprego', icon: 'pi pi-cog', },
                    { label: 'RAIS', icon: 'pi pi-cog', },
                    { label: 'Arquivos para Bancos', icon: 'pi pi-cog', },
                ]
            },
        ]
    }    

    // onMenuClick() {
    //     this.app.onMenuClick()
    // }
}
