<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3" style="font-weight: bold; font-size: 16px;">CNPJ:
                    11.378.004/0001-24
                </div>
                <div class="p-field p-col-12 p-md-2 p-offset-4" style="font-weight: bold; font-size: 14px;">Data Inclusão:
                    01/01/2021
                </div>
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 14px;">Data Alteração:
                    10/09/2021
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-8">
                    <app-input [label]="'Descrição da Lotação'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'Código'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <app-select [label]="'Tipo de Lotação'"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <app-select [label]="'Tomador de Obra'"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2 p-offset-6">
                    <button pButton type="button" [label]="'Cancelar'" class="p-button-danger" (click)="cancelar()"></button>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <button pButton type="button" [label]="'Avançar'"></button>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <button pButton type="button" [label]="'Salvar'"></button>
                </div>
            </div>
        </p-card>
        &nbsp;
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Inicio da Vigência'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Fim da Vigência'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Código FPAS'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Código Terceiro'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2 p-offset-2" style="margin-top: 16px;">
                    <button pButton type="button" [label]="'Alterar Vigência'"></button>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'Aliq. RAT'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'FAP'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'FAP Operador Portuário'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'RAT Operador Portuário'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                    <app-select [label]="'Folha de Processo Judicial'"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Cod. Terceiro Susp.'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2 p-offset-3" style="margin-top: 16px;">
                    <button pButton type="button" [label]="'Retificar'"></button>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-top: 16px;">
                    <button pButton type="button" [label]="'Criar Nova Vigência'"></button>
                </div>
            </div>
        </p-card>
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>