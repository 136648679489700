import { Util } from 'src/app/controller/Util';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConfirmationService, Message, MessageService, SelectItem} from "primeng/api";
import {Router} from "@angular/router";
import { NetworkService } from 'src/app/services/network.service';
import { getUrlHunnoRh, qtdLinhas } from 'src/app/controller/staticValues';
import { BaseListSimples } from 'src/app/controller/BaseListSimples';

@Component({
  selector: 'app-esocial',
  templateUrl: './esocial.component.html',
  styleUrls: ['./esocial.component.css']
})
export class EsocialComponent extends BaseListSimples implements OnInit, OnDestroy {

        // public entidade: string = 'empregador'
    jaPesquisou = false
    pagina = 0;
    public first: number = 0
    public loading: boolean
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas()
    public totalItens: number
    // public lista: Object[] = []
    lista2 = []
    @ViewChild('inputPesquisa') public inputPesquisa
    @ViewChild('selectValue') public selectValue
    public selectSort: SelectItem[] = [{label: 'ID', value: 'ID'}, {label: 'NOME', value: 'NOME'}]
    opcoesTable = [
        {label: 'Alterar', icon: 'fa fa-edit', command: (e) => this.editar(e)},
        {label: 'Excluir', icon: 'fa fa-close', command: (e) => this.deletar(e)},     
    ]

    

    constructor(public messageService: MessageService, public confirmationService: ConfirmationService, public networkService: NetworkService, public router: Router) {
        super(networkService, getUrlHunnoRh(), 'relempregadorVO')
    }

    ngOnInit() {                
        this.carregarLista()
        this.lista
    }

    pressionaEnter(e) {
        if (e.key === 'Enter') this.carregarLista()
    }

    

    linkPessoa(v) {
        this.router.navigate([`/historico-pessoa/${v.Id}/pedido`])
    }

    

    


    public deletar(rowData) {
        this.confirmationService.confirm({
            message: `Você tem certeza que deseja deletar?`,
            acceptLabel: `Sim`,
            rejectLabel: `Não`,
            accept: () => {
                this.$subscriptionDeletar = this.networkService.deletar('', this.entidade, rowData.IdPessoaEmpresa).subscribe(res => {
                        this.carregarLista()
                    })
            }
        })
    }

    public editar(rowData) {
        this.router.navigate([`/${this.entidade}/${Util.cadastroRoute()}/${rowData.IdPessoaEmpresa}`])
    }

    navegar() {                
        // this.cadastrarPessoa.nativeElement.click()        
    }

    ngOnDestroy(): void {
        // if(this.$subscriptionListar) this.$subscriptionListar.unsubscribe()
        // if(this.$subscriptionDeletar) this.$subscriptionDeletar.unsubscribe()
    }

}
