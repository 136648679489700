<div class="ui-g-12 ui-fluid">
    <label *ngIf="label" [attr.for]="id">{{label}}</label><br *ngIf="label"/>
    <div class="p-inputgroup ui-g-nopad"  style="width: 99%;" >
                <input [ngClass]="{'invalid': campoInvalido}" [type]="type" [id]="id" [placeholder]="placeholder" [readOnly]="!!isReadOnly" [(ngModel)]="inputValue"
                       (blur)="openModal($event)" (keypress)="openModal($event)" autocomplete="off" pInputText #inputRef/>
                <button style="width: 40px;" pButton type="button" icon="fa fa-search" (click)="modalPesquisa = true" tabindex="-1"></button>
            </div>
        </div>

<app-modal-pesquisar-plano-conta [modalVisible]="modalPesquisa" (dadosSalvos)="valorPesquisado($event)"
                            (closeModal)="modalPesquisa = false" [data]="valorParcialParaPesquisar" [filtroAdicional]="filtroAdicional"></app-modal-pesquisar-plano-conta>
