export class Empregador {

    Id: number = 0
    DataInclusao: string = ''
    DataAlteracao: string = ''
    Nome: string = ''
    IndEmpresaTrabTemporario: string = ''
    NrRegMteTrabTemporario: string = ''
    IndTipoInscricao: number = 0
    ContractorId: number = 0
    InscricaoNr: string = ''
    InscricaoTipo: number = 0
    TipoCaepf: number = 0
    Filial: boolean = false
    IdMatriz: number = 0
    Matriz: boolean = false
    ContractorClientId: string = ''

    static datas() {
        return ['DataInclusao', 'DataAlteracao']
    }

    static checkbox() {
        return ['Filial','Matriz']
    }

    static referencias(){
        return [{chave: 'ContractorClientId', referencia: 'ContractorClient'}]
    }

    static relacionamentos() {
        return ['ContractorClientId']
    }

    static expanded(){
        return ['ContractorClientId']
    }

}