import { Router } from '@angular/router';
import { DadosDefaultService } from './services/dados-default.service';
import { Component, ViewChild, ElementRef } from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { EMPRESA_STORAGE_KEY } from './controller/staticValues';

@Component({
    selector: 'app-topbar',
    templateUrl: `./app.toqbar.component.html`
})
export class AppTopbarComponent {

    public empresa = ''

    @ViewChild('trocarempresa') trocarEmpresa: ElementRef;

    constructor(public app: AppMainComponent, public dadosDefault: DadosDefaultService, public router: Router) {}

    ngOnInit(): void {        
        if(JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY))['nome'])
            this.empresa = JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY))['nome']
        if(JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY))['Nome'])
            this.empresa = JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY))['Nome']
    }

    deslogar() {
        sessionStorage.clear()
        this.router.navigate(['/login']);
    }

    abrirModalTrocarEmpresa(){
        this.trocarEmpresa.nativeElement.click();
    }

}
