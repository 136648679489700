import { LotacaoComponent } from './pages/empregador/lotacao/lotacao-cadastro/lotacao.component';
import { TomadorComponent } from './pages/empregador/tomador/tomador-cadastro/tomador.component';
import { EmpregadorPrincipalComponent } from './pages/empregador/empregador-principal/empregador-principal.component';
import { EmpregadorCadastroComponent } from './pages/empregador/empregador-cadastro/empregador-cadastro.component';
import { SociosComponent } from './pages/socios/socios.component';
import { FuncionarioComponent } from './pages/funcionario/funcionario.component';
import { EmpregadorComponent } from './pages/empregador/empregador.component';
import { VisaoGeralComponent } from './pages/dashboard/visao-geral/visao-geral.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {LoginComponent} from './auth/login/login.component'
import {LoginGuard} from './auth/service/login.guard';
import {SelecaoEmpresaComponent} from './auth/selecao-empresa/selecao-empresa.component';
import { AuthGuard } from './auth/service/auth-guard';
import { CarregarDadosGuard } from './guards/carregar-dados.guard';
import { CarregarDadosLoginComponent } from './pages/carregar-dados-login/carregar-dados-login.component';
import { EstabelecimentoComponent } from './pages/empregador/estabelecimento/estabelecimento-cadastro/estabelecimento.component';
import { RubricasComponent } from './pages/empregador/rubricas/rubricas-cadastro/rubricas.component';
import { CargosComponent } from './pages/empregador/cargos/cargos-cadastro/cargos.component';

const routes: Routes = [
    {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
    { path: 'selecao-empresa/:value', component: SelecaoEmpresaComponent },
    {path: `carregar-dados/:USUARIO/:EMPRESA`, component: CarregarDadosLoginComponent},

    {
        path: '', component: AppMainComponent, canActivate: [AuthGuard],
         children: [
             {path: 'home', component: VisaoGeralComponent},
             {path: 'empregador', component: EmpregadorComponent},
             {path: 'funcionario', component: FuncionarioComponent},
             {path: 'socios', component: SociosComponent},
             {path: 'empregador/cadastro', component: EmpregadorPrincipalComponent},
             {path: 'tomador/cadastro', component: TomadorComponent},
             {path: 'lotacao/cadastro', component: LotacaoComponent},
             {path: 'estabelecimento/cadastro', component: EstabelecimentoComponent},
             {path: 'rubricas/cadastro', component: RubricasComponent},
             {path: 'cargos/cadastro', component: CargosComponent},
            //  {path: 'empregador/cadastro/:id', component: EmpregadorCadastroComponent},

        ]
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule {
}
