<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'max-width':'800px'}" [maximizable]="true"
          [header]="'Pesquisar Plano de Contas'" [(visible)]="modalVisible" (onHide)="fecharModal()">
    <div class="p-grid p-fluid">
        <p-card [style]="{marginBottom: '15px'}">
            <div class="p-inputgroup" >
                <input #inputPesquisa appUppercaseNotForm (keypress)="pressionaEnter($event)" pInputText>
                <button pButton type="button" icon="fa fa-search" (click)="carregarLista()"
                        class="ui-button-warn"></button>
            </div>
            &nbsp;
            <p-table styleClass="p-datatable-customers p-datatable-gridlines p-datatable-striped p-datatable-sm" [(selection)]="value" selectionMode="single" [paginator]="true" [rows]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" *ngIf="modalVisible" #p [lazy]="true" (onLazyLoad)="lazyLoad($event)">

                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '80%'}"> Descrição </th>
                        <th [ngStyle]="{width: '20%'}"> Classificação </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                        <td>{{rowData.Descricao}}</td>
                        <td>{{rowData.Classificacao}}</td>
                    </tr>
                </ng-template>

            </p-table>
            &nbsp;

            <p-footer>
                <app-footer-page [end]="true" (cancelar)="fecharModal()" (confirmar)="confirmar()" [labelConfirmar]="'Confirmar'"></app-footer-page>
                <!-- <div class="p-md-12 p-fluid">
                    <div class="p-offset-5 p-col-2">
                        <button pButton type="button" label="Cancelar"  class="p-button-danger" (click)="fecharModal()"></button>
                    </div>
                    <div class="p-col-2">
                        <button pButton type="button" label="Confirmar" (click)="confirmar()"></button>
                    </div>
                </div> -->
            </p-footer>
            </p-card>
        </div>
        &nbsp;
        <p-toast position="bottom-right"></p-toast>
</p-dialog>
