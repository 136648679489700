<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3" style="font-weight: bold; font-size: 16px;">CNPJ:
                    11.378.004/0001-24
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <app-input [label]="'Código Interno'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-left: 100px;">
                    <div>
                        <span>Ativo</span>
                    </div>
                    <div formGroupName="PessoaForm">
                        <p-toggleButton [style]="{'width':'100px'}" [onLabel]="onLabel" [offLabel]="offLabel"
                            formControlName="Ativo"></p-toggleButton>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Nome do Estabelecimento'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-select [label]="'Tipo de Inscrição'"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Inscrição'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <div>Cep</div>
                    <div style="display: flex;justify-content: flex-start;">
                        <div style="padding-right: 0px;padding-left: 0px;">
                            <p-inputMask id="cep" (onBlur)="verificaCepValido($event)" mask="99999-999">
                            </p-inputMask>
                        </div>
                        <button pButton type="button" icon="fa fa-search" (click)="modalCepVisible = true"></button>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-3" style="font-weight: bold; font-size: 20px; margin-top: 20px;">
                    Cidade:
                    Feira de Santana
                </div>
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 20px; margin-top: 20px;">UF:
                    BA
                </div>
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 20px; margin-top: 20px;">IBGE:
                    2910800
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Número'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-10">
                    <app-input [label]="'Endereço'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Complemento'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Bairro'"></app-input>
                </div>
            </div>
            <app-page-divided>
                <div principal>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-fluid p-col-12 p-md-6">
                            <app-input [label]="'Contato'"></app-input>
                        </div>
                        <div class="p-fluid p-col-12 p-md-6">
                            <app-input [label]="'Email'"></app-input>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-fluid p-col-12 p-md-4">
                            <app-input-telefone [label]="'Celular'"></app-input-telefone>
                        </div>
                        <div class="p-fluid p-col-12 p-md-4">
                            <app-input-telefone [label]="'Fone 1'"></app-input-telefone>
                        </div>
                        <div class="p-fluid p-col-12 p-md-4">
                            <app-input-telefone [label]="'Fone 2'"></app-input-telefone>
                        </div>
                    </div>
                </div>
                <div secundario>
                    <p-card [style]="{marginTop: '20px'}" [header]="'Foto'" formGroupName="ProdutoForm">
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                            [containWithinAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="640"
                            [style.display]="showCropper ? null : 'none'" format="png"
                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
                            [onlyScaleDown]="true" [roundCropper]="false"></image-cropper>
                        <div *ngIf="!imageChangedEvent" class="img-container">
                            <img [src]="imagem" alt="">
                        </div>
                        <input type="file" (change)="fileChangeEvent($event)" #uploadImagem class="esconder" />
                        <button pButton label="Upload Imagem" (click)="clickUploadImagem()"></button>
                    </p-card>
                </div>
            </app-page-divided>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2 p-offset-8">
                    <button pButton type="button" class="p-button-danger" [label]="'Cancelar'" (click)="cancelar()"></button>
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <button pButton type="button" [label]="'Salvar'"></button>
                </div>
            </div>

            <app-painel title="Vigência Estabelecimento">
                <p-card>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6" style="margin-top: 16px;">
                            <div class="p-inputgroup">
                                <input #inputPesquisa appUppercaseNotForm pInputText placeholder="Cnae Preponderante">
                                <button pButton type="button" icon="fa fa-search" class="ui-button-warn" ></button>
                            </div>
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <button pButton type="button" [label]="'Salvar'"></button>
                        </div>
                    </div>
                </p-card>
            </app-painel>
        </p-card>
    </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>