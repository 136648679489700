<div class="p-grid p-fluid">
    <div class="p-col-12 p-offset">
        <div class="menu" style="margin-left: 50px;">
            <p-tabMenu #tab [model]="items" [activeItem]="activeItem" (click)="setActiveItem(tab)">
                <ng-template pTemplate="item" let-item let-i="index">
                    <div style="position: relative; text-align: center; min-width: 7.2em">
                        <div class="p-menuitem-icon" [ngClass]="item.icon" *ngIf="item.icon" style="font-size: 2em">
                        </div>
                        <div class="p-menuitem-text">
                            {{item.label}}
                        </div>
                    </div>
                </ng-template>
            </p-tabMenu>
        </div>

        <div class="p-col-12">

            <app-empregador-cadastro *ngIf="currentIndex === 0"></app-empregador-cadastro>

            <app-estabelecimento-list *ngIf="currentIndex === 1"></app-estabelecimento-list>

            <app-lotacao-list *ngIf="currentIndex === 2"></app-lotacao-list>

            <app-esocial *ngIf="currentIndex === 3"></app-esocial>

            <app-rubricas-list *ngIf="currentIndex === 5"></app-rubricas-list>

            <app-cargos-list *ngIf="currentIndex === 6"></app-cargos-list>

            <app-tomador-list *ngIf="currentIndex === 7"></app-tomador-list>

            <!-- <app-parametros-nota-fiscal *ngIf="currentIndex === 1"></app-parametros-nota-fiscal>

        <app-parametros-contabeis *ngIf="currentIndex === 2"></app-parametros-contabeis>

        <app-parametros-empresa *ngIf="currentIndex === 3"></app-parametros-empresa> -->

            <!--        <app-parametros-financeiros *ngIf="currentIndex === 4"></app-parametros-financeiros>-->


        </div>
    </div>