<div class="login-body-fixo">
    <div class="card login-panel p-fluid">
        <div class="p-grid">
            <div class="p-col-12">
                <img src="assets/layout/images/hunno_novo.png">
                <h1>Bem vindo ao Hunno RH</h1>
            </div>
            <form [formGroup]="form" (submit)="logarUsuario()">
                <div style="margin-left: 20px;" class="p-col-12">
                    <span class="p-float-label">
                        <input type="text" autocomplete="off" placeholder="Usuario" formControlName="email" pInputText>
                        <!-- <label for="user">Username</label> -->
                    </span>
                </div>
                <div style="margin-left: 20px;" class="p-col-12">
                    <span class="p-float-label">
                        <input type="password" autocomplete="off" placeholder="Senha" formControlName="password"
                            pInputText>
                        <!-- <label for="password">Password</label> -->
                    </span>
                </div>
                <div style="margin-left: 20px;" class="p-col-12">
                    <button class="button-fixo" pButton type="submit" label="Acessar" style="width: 100%" icon="pi pi-user"></button>
                    <!-- <button class="button-fixo" pButton type="button" label="Contratar" style="width: 100%" icon="pi pi-question-circle"></button> -->
                </div>
            </form>
        </div>
    </div>

    <app-modal-selecionar-empresa-escritorio [modalVisible]="modalEmpresa"
                      (closeModal)="modalEmpresa = false"app-modal-selecionar-empresa-escritorio></app-modal-selecionar-empresa-escritorio>
</div>