import { RubricasComponent } from './pages/empregador/rubricas/rubricas-cadastro/rubricas.component';
import { CargosComponent } from './pages/empregador/cargos/cargos-cadastro/cargos.component';
import { LotacaoComponent } from './pages/empregador/lotacao/lotacao-cadastro/lotacao.component';
import { TomadorComponent } from './pages/empregador/tomador/tomador-cadastro/tomador.component';
import { EstabelecimentoComponent } from './pages/empregador/estabelecimento/estabelecimento-cadastro/estabelecimento.component';
import { EmpregadorPrincipalComponent } from './pages/empregador/empregador-principal/empregador-principal.component';
import { EmpregadorCadastroComponent } from './pages/empregador/empregador-cadastro/empregador-cadastro.component';
import { SociosComponent } from './pages/socios/socios.component';
import { FuncionarioComponent } from './pages/funcionario/funcionario.component';
import { EmpregadorComponent } from './pages/empregador/empregador.component';
import { VisaoGeralComponent } from './pages/dashboard/visao-geral/visao-geral.component';
import { GeralLivroCaixaXNotasChartComponent } from './pages/dashboard/geral-livro-caixa-x-notas-chart/geral-livro-caixa-x-notas-chart.component';
import { GeralEvolucaoSaldoLivroCaixaChartComponent } from './pages/dashboard/geral-evolucao-saldo-livro-caixa-chart/geral-evolucao-saldo-livro-caixa-chart.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import { SentryErrorHandler } from './auth/service/sentry-error-handler'
import { ConfirmationService, MessageService } from 'primeng/api'
import { AuthInterceptor } from './auth/service/auth-interceptor';
import { ErrorInterceptor } from './auth/service/error-interceptor';
import {ImageCropperModule} from "ngx-image-cropper";
import {NgxViacepModule} from "@brunoc/ngx-viacep";

// Application Components
import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppInlineProfileComponent} from './app.profile.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightpanelComponent} from './app.rightpanel.component';
import {AppTopbarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {LoginComponent} from './auth/login/login.component';
import {SelecaoEmpresaComponent} from './auth/selecao-empresa/selecao-empresa.component';
import {ModaisModule} from './modais/modais.module';
import {DiretivasModule} from './diretivas/diretivas.module';
import {ComponentsModule} from './components/components.module';
import {PipesModule} from './pipes/pipes.module';
// Application services
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';

import {TemaModule} from './tema.module';
import { LayoutsModule } from './layout/layouts.module';
import { CarregarDadosLoginComponent } from './pages/carregar-dados-login/carregar-dados-login.component';
import { CargosListComponent } from './pages/empregador/cargos/cargos-list.component'
import { RubricasListComponent } from './pages/empregador/rubricas/rubricas-list.component'
import { TomadorListComponent } from './pages/empregador/tomador/tomador-list.component'
import { EstabelecimentoListComponent } from './pages/empregador/estabelecimento/estabelecimento-list.component'
import { EsocialComponent } from './pages/empregador/esocial/esocial.component'
import { LotacaoListComponent } from './pages/empregador/lotacao/lotacao-list.component'

@NgModule({
    imports: [BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppCodeModule,
        TemaModule,
        DiretivasModule,
        ComponentsModule,
        PipesModule,
        ModaisModule,
        LayoutsModule,
        ImageCropperModule,
        NgxViacepModule,

    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineProfileComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppRightpanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        LoginComponent,
        SelecaoEmpresaComponent,
        GeralEvolucaoSaldoLivroCaixaChartComponent,
        GeralLivroCaixaXNotasChartComponent,
        VisaoGeralComponent,
        EmpregadorComponent,
        FuncionarioComponent,
        SociosComponent,
        EmpregadorCadastroComponent,
        EmpregadorPrincipalComponent,
        EstabelecimentoComponent,
        TomadorComponent,
        LotacaoComponent,
        CargosComponent,
        RubricasComponent,
        CarregarDadosLoginComponent,
        TomadorListComponent,
        EsocialComponent,
        LotacaoListComponent,
        EstabelecimentoListComponent,
        RubricasListComponent,
        CargosListComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {provide: ErrorHandler, useClass: SentryErrorHandler},
        MenuService, AppBreadcrumbService,
        MessageService,
        ConfirmationService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
