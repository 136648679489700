import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-empregador-principal',
  templateUrl: './empregador-principal.component.html',
  styleUrls: ['./empregador-principal.component.css']
})
export class EmpregadorPrincipalComponent implements OnInit {

    currentIndex = 0;
    activeItem: any;
    items = [
        {label: 'Principal', icon: 'fa fa-fw fa-book'},
        {label: 'Estabelecimentos', icon: 'fa fa-fw fa-book'},
        {label: 'Lotação', icon: 'fa fa-fw fa-book'},
        {label: 'E-Social', icon: 'fa fa-fw fa-book'},
        {label: 'Parametros', icon: 'fa fa-fw fa-book'},
        {label: 'Rubricas', icon: 'fa fa-fw fa-book'},
        {label: 'Cargos', icon: 'fa fa-fw fa-book'},
        {label: 'Tomadores', icon: 'fa fa-fw fa-book'},
    ];

    // @ViewChild(ParametrosGeraisComponent, {static: false}) parametrosGeraisComponent: ParametrosGeraisComponent;
    // @ViewChild(ParametrosNotaFiscalComponent, {static: false}) parametrosNotaFiscalComponent: ParametrosNotaFiscalComponent;
    // @ViewChild(ParametrosContabeisComponent, {static: false}) parametrosContabeisComponent: ParametrosContabeisComponent;
    // @ViewChild(ParametrosEmpresaComponent, {static: false}) parametrosEmpresaComponent: ParametrosEmpresaComponent;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
      this.currentIndex = 0
      this.activeItem = this.items[0]

      this.route.queryParamMap.subscribe(({params}: any) => {
          if(!params || !params.page) return;

          switch (params.page) {
              case 'Lotacao':
                        this.setItem(2)
                  break;
              case 'e-social':
                  this.setItem(3)
                  break;
              case 'Parametros':
                  this.setItem(4)
                  break;
              case 'Rubricas':
                  this.setItem(5)
                  break;
              case 'Cargos':
                  this.setItem(6)
                  break;
              case 'Tomador':
                  this.setItem(7)
                  break;
              case 'Certificado':
              case 'CertificadoDigital':
                  this.setItem(1)
                  setTimeout(() => {
                //   this.parametrosNotaFiscalComponent.painelDadosEssenciaisUm = true;
                  }, 1000)
                  break;
              case 'Estabelecimento':
                    this.setItem(1)
                  break;
              case 'Principal':
                    this.setItem(0)
                  break;
          }
          this.router.navigate([], {
              queryParams: {
                  page: null,
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
          })
      })

  }

    setActiveItem(e) {
        this.currentIndex = this.items.findIndex(v => v === e.activeItem)
    }

    setItem(index) {
        this.currentIndex = index
        this.activeItem = this.items[index]
    }

}
