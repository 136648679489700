<!-- <app-page-header>
    <div style="display: flex;justify-content: flex-end;margin-left: 150px;" *ngIf="idPessoa">
        <div>
            <h3 style="color: rgb(0,175,250); cursor: pointer" (click)="verHistorico()">Ver Historico</h3>
        </div>
        <div style="margin-left: 20px;">
            <button pButton label="Atualizar via Sefaz" (click)="atualizaPessoaSefaz()"></button>
        </div>
        <div style="margin-left: 20px;">
            <button pButton label="Atualizar via Web" (click)="atualizaPessoaWeb()"></button>
        </div>
    </div>
</app-page-header> -->
<ng-container *ngTemplateOutlet="conteudo"></ng-container>
<ng-template #conteudo>
    <div [formGroup]="form">
        <!-- <app-page-divided>
            <div principal> -->
        <!--CalcularTributosManualmente-->
        <p-card>
            <div class="p-fluid p-formgrid p-grid">
                <!-- <div class="p-field p-col-12 p-md-4" formGroupName="PessoaForm">
                            <app-input-cpf-cnpj [label]="'Cpf / Cnpj'" [row]="true" formControlName="CpfCnpj"
                                (onBlur)="buscaCnpj($event)" (onKeypress)="buscaCnpj($event)"></app-input-cpf-cnpj>
                        </div> -->
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 16px; margin-top: 20px;">CNPJ:
                    11.378.004/0001-24
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Código Interno'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-2" style="margin-left: 100px;">
                    <div>
                        <span>Ativo</span>
                    </div>
                    <div formGroupName="PessoaForm">
                        <p-toggleButton [style]="{'width':'100px'}" [onLabel]="onLabel" [offLabel]="offLabel"
                            formControlName="Ativo"></p-toggleButton>
                    </div>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                <!-- <div *ngIf="form.get('PessoaForm').get('Tipo').value !== 'J'" class="p-field p-col-12 p-md-8">
                            <app-input [label]="'Nome'" [tentativa]="tentativa" formControlName="Nome"></app-input>
                        </div> -->
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Razão Social'" formControlName="Nome"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Nome fantasia'" formControlName="Fantasia"></app-input>
                </div>
                <!-- <div class="p-field p-col-12 p-md-4">
                            <app-select [label]="'Classificação Tributária'"></app-select>
                        </div> -->
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <div>Cep</div>
                    <div style="display: flex;justify-content: flex-start;">
                        <div style="padding-right: 0px;padding-left: 0px;">
                            <p-inputMask id="cep" (onBlur)="verificaCepValido($event)" mask="99999-999">
                            </p-inputMask>
                        </div>
                        <button pButton type="button" icon="fa fa-search" (click)="modalCepVisible = true"></button>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-3" style="font-weight: bold; font-size: 20px; margin-top: 20px;">
                    Cidade:
                    Feira de Santana
                </div>
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 20px; margin-top: 20px;">UF:
                    BA
                </div>
                <div class="p-field p-col-12 p-md-2" style="font-weight: bold; font-size: 20px; margin-top: 20px;">IBGE:
                    2910800
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2">
                    <app-input [label]="'Número'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-10">
                    <app-input [label]="'Endereço'"></app-input>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Complemento'"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <app-input [label]="'Bairro'"></app-input>
                </div>
            </div>
            <app-page-divided>
                <div principal>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-fluid p-col-12 p-md-6">
                            <app-input [label]="'Contato'"></app-input>
                        </div>
                        <div class="p-fluid p-col-12 p-md-6">
                            <app-input [label]="'Email'"></app-input>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-fluid p-col-12 p-md-4">
                            <app-input-telefone [label]="'Celular'"></app-input-telefone>
                        </div>
                        <div class="p-fluid p-col-12 p-md-4">
                            <app-input-telefone [label]="'Fone 1'"></app-input-telefone>
                        </div>
                        <div class="p-fluid p-col-12 p-md-4">
                            <app-input-telefone [label]="'Fone 2'"></app-input-telefone>
                        </div>
                    </div>
                </div>
                <div secundario>
                    <p-card [style]="{marginTop: '20px'}" [header]="'Foto'" formGroupName="ProdutoForm">
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                            [containWithinAspectRatio]="true" [aspectRatio]="4 / 4" [resizeToWidth]="640"
                            [style.display]="showCropper ? null : 'none'" format="png"
                            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                            (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
                            [onlyScaleDown]="true" [roundCropper]="false"></image-cropper>
                        <div *ngIf="!imageChangedEvent" class="img-container">
                            <img [src]="imagem" alt="">
                        </div>
                        <input type="file" (change)="fileChangeEvent($event)" #uploadImagem class="esconder" />
                        <button pButton label="Upload Imagem" (click)="clickUploadImagem()"></button>
                    </p-card>
                </div>
            </app-page-divided>
            <!-- <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-6 p-md-6">
                            <app-select [label]="'Natureza Jurídica'"></app-select>
                        </div>
                        <div class="p-field p-col-6 p-md-6">
                            <app-select [label]="'Porte da empresa'"></app-select>
                        </div>
                    </div> -->

            <app-painel title="Dados Tributários">
                <p-card>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3">
                            <app-date [label]="'Inicio Validade'"></app-date>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                            <app-date [label]="'Fim Validade'"></app-date>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Tributária'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Indicador de Cooperativa'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Indicador de Construtora'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Ind. Entidade Educacional Sem Fins Lucrativo'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Indicadores de Porte Empresarial'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Indicadores de desoneração de Folha'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Ind. de Opção INSS Produtor Rural'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Ind. Empresa Trabalho Temporário'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <app-input [label]="'Nº Registro Ministério do Trabalho'"></app-input>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Natureza Jurídica'"></app-select>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Ind. Opção Registro Eletrônico Empregado'"></app-select>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" formGroupName="PessoaForm">
                        <div class="p-field p-col-12 p-md-6">
                            <app-select [label]="'Ind. Acordo Isenção Organização Internacional'"></app-select>
                        </div>
                    </div>
                    <app-painel title="Dados da Isenção">
                    </app-painel>
                </p-card>
            </app-painel>
            &nbsp;
            <app-painel title="Horários">
                <p-card>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-6">
                            <app-input [label]="'Descrição do Horário'"></app-input>
                        </div>
                        <div class="p-field p-col-12 p-md-2" style="margin-left: 100px;">
                            <div>
                                <span>Ativo</span>
                            </div>
                            <div formGroupName="PessoaForm">
                                <p-toggleButton [style]="{'width':'100px'}" [onLabel]="onLabel" [offLabel]="offLabel"
                                    formControlName="Ativo"></p-toggleButton>
                            </div>
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-2"
                            style="font-weight: bold; font-size: 14px; margin-top: 10px;">Data Inclusão:
                            01/01/2021
                        </div>
                        <div class="p-field p-col-12 p-md-2"
                            style="font-weight: bold; font-size: 14px; margin-top: 10px;">Data Alteração:
                            10/09/2021
                        </div>
                        <div class="p-field p-col-12 p-md-2 p-offset-4">
                            <button pButton type="button" [label]="'Cancelar'" class="p-button-danger"></button>
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <button pButton type="button" [label]="'Salvar'"></button>
                        </div>
                    </div>
                </p-card>
            </app-painel>
            &nbsp;
            <app-painel title="Processos">
            </app-painel>
        </p-card>
    </div>
    <!-- </app-page-divided>

    </div> -->

</ng-template>
<p-toast position="bottom-right"></p-toast>